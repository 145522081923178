import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import { DataGrid as DataGridComponent, GridColDef, GridPageChangeParams, GridSortModelParams } from '@material-ui/data-grid';
import ExportToExcel from '../ExportToExcel';
import { useStyles } from './styles';

export type TableColumn = {
  avatar?: boolean;
  avatarFallback?: string;
  currency?: boolean;
  key: string;
  label: string;
  numeric?: boolean;
  render?: (value: any) => JSX.Element | string;
};

type DataGridProps = {
  addRoute?: string;
  canAdd?: boolean;
  canExport?: boolean;
  columns: GridColDef[];
  rows: ReadonlyArray<any>;
  elevation?: number;
  title?: string;
};

export type DataGridState = {
  isEmpty: boolean
  showError: boolean;
};

const defaultState: DataGridState = {
  isEmpty: true,
  showError: false,
};

export default function DataGrid({
  addRoute,
  canAdd,
  canExport,
  columns,
  rows,
  elevation = 1,
  title,
}: DataGridProps): JSX.Element {
  const [state, setState] = useState<DataGridState>(defaultState);
  const [pageSizeState, setPageSizeState] = React.useState<number>(10);
  const classes = useStyles();

  useEffect(() => {
    setState(defaultState);
    if (rows.length > 0) {
      setState({ isEmpty: false, showError: true });

      for (let i = 0; i < columns.length; i++) {
        if (columns[i].field.toString().toLowerCase() === 'id') {
          setState({ isEmpty: false, showError: false });
          break;
        }
      }
    }
  }, [columns, rows]);

  const handlePageSizeChange = (params: GridPageChangeParams) => {
    setPageSizeState(params.pageSize);
  };

  // const handleSortModelChange = (params: GridSortModelParams) => {
  //   console.log(params.api);
  // };

  return (
    <Paper className={classes.container} elevation={elevation}>
      {!state.showError && (
      <>
        <div className={classes.titleContainer}>
          {!!title && (
            <Typography variant="h6" className={classes.title}>
              {title}
            </Typography>
          )}
          {canAdd && addRoute && (
            <Button
              component={Link}
              variant="contained"
              color="primary"
              startIcon={<AddBox />}
              to={addRoute}
            >
              Incluir
            </Button>
          )}
          {canExport && !state.showError && !state.isEmpty && (
            <ExportToExcel data={rows} fileName={title || 'Relatorio'} />
          )}
        </div>
        <Grid className={classes.grid}>
          <DataGridComponent
            pageSize={pageSizeState}
            onPageSizeChange={handlePageSizeChange}
            rowsPerPageOptions={[10, 50, 100]}
            pagination
            rows={state.isEmpty ? [] : rows}
            columns={state.isEmpty ? [] : columns}
            columnBuffer={columns.length}
            autoHeight
            rowHeight={40}
            getRowId={(row) => row.id || row.ID || row.Id}
            // onSortModelChange={handleSortModelChange}
          />
        </Grid>
      </>
      )}
      {state.showError && (
        <Grid>
          <div className={classes.titleContainer}>
            <Typography variant="h6" className={classes.errorMessage}>
              Falha ao carregar relatório, pois ele não possui a coluna Id
            </Typography>
          </div>
        </Grid>
      )}
    </Paper>
  );
}
